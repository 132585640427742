import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const LoadingIndicator = ({ size, style, withDelay, delay = 2000 }) => {
  const computedClass = size ? `spinner ${size}` : 'spinner';
  const [showIndicator, setShowIndicator] = useState(!withDelay);
  console.log(style);
  useEffect(() => {
    let timer;
    if (withDelay) {
      timer = setTimeout(() => setShowIndicator(true), delay);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [delay, withDelay]);

  return (
    showIndicator && (
      <div className="loading" style={style}>
        <div className={computedClass}>
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
    )
  );
};

LoadingIndicator.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  withDelay: PropTypes.bool,
  delay: PropTypes.number,
};

export default LoadingIndicator;
