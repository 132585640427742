import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { trimUriSegments } from '../common/uri';
import moment from 'moment';

const getBaseLinkPath = (forDepartment, location) =>
  trimUriSegments(location.pathname, forDepartment ? 1 : 2);

const getResource = item => {
  if (item.resource === 'departmentCateringAvailability') return 'Department';
  if (item.resource === 'pulseOrder') return 'Pulse Order';
  return capitalize(item.resource);
};

const getUserName = item => {
  if (item.first_name === null && item.last_name === null) return '';
  return `${item.first_name} ${item.last_name}`;
};

const getTime = item => moment(item.created_at).format('MM/DD/YYYY h:mm A');

const initializeDataRow = (item, actionText) => ({
  user: getUserName(item),
  resource: getResource(item),
  action: (
    <span className={`activity-log-action--${actionText.toLowerCase()}`}>
      {actionText}
    </span>
  ),
  time: getTime(item),
});

const contactItemDetails =
  ({ forDepartment, location }) =>
  item => {
    const actionText = item.before
      ? item.after
        ? 'Updated'
        : 'Deleted'
      : 'Created';
    const hasDepartment = !!item.department_slug;
    const baseLinkPath = getBaseLinkPath(forDepartment, location);

    const dataRow = initializeDataRow(item, actionText);

    if (!forDepartment && hasDepartment) {
      dataRow.department = (
        <span>
          {item.department_type}:&nbsp;
          <Link
            to={`${baseLinkPath}/${item.department_type}/${item.department_slug}`}
          >
            {item.department_name}
          </Link>
        </span>
      );
    }

    const contactLink = item.info.is_guest
      ? null
      : !forDepartment
      ? `${baseLinkPath}/${item.department_type}/${item.department_slug}/contacts/list?contact=${item.info.contact_id}`
      : `${baseLinkPath}/contacts/list?contact=${item.info.contact_id}`;

    switch (actionText) {
      case 'Created':
        if (item.resource === 'contact') {
          dataRow.details = item.after.first_name ? (
            <Link to={contactLink}>
              {item.after.first_name} {item.after.last_name}
            </Link>
          ) : (
            'Guest'
          );
        }
        break;
      case 'Updated':
        if (item.resource === 'contact') {
          const changedName = item.before.first_name
            ? `${item.before.first_name} ${item.before.last_name}`
            : 'Guest';
          dataRow.details = (
            <span>
              From {changedName} to&nbsp;
              {item.info.is_guest ? (
                `${item.after.first_name} ${item.after.last_name}`
              ) : (
                <Link to={contactLink}>
                  {item.after.first_name} {item.after.last_name}
                </Link>
              )}
            </span>
          );
        }
        break;
      case 'Deleted':
        if (item.resource === 'contact') {
          const deletedName = item.before.first_name
            ? `${item.before.first_name} ${item.before.last_name}`
            : 'Guest';
          dataRow.details = <span>{deletedName}</span>;
        }
        break;
      default:
        break;
    }

    return dataRow;
  };

const credentialItemDetails =
  ({ forDepartment, location }) =>
  item => {
    const actionText = item.action[0].toUpperCase() + item.action.substr(1);
    const hasDepartment = !!item.department_slug;
    const baseLinkPath = getBaseLinkPath(forDepartment, location);

    const dataRow = initializeDataRow(item, actionText);

    if (!forDepartment && hasDepartment) {
      dataRow.department = (
        <span>
          {item.department_type}:&nbsp;
          <Link
            to={`${baseLinkPath}/${item.department_type}/${item.department_slug}`}
          >
            {item.department_name}
          </Link>
        </span>
      );
    }

    dataRow.details = `${item.info.credential_type_name} [Qty: ${
      item.info.quantity
    }] for ${
      item.info.requested_first_name
        ? item.info.requested_first_name + ' ' + item.info.requested_last_name
        : 'Group'
    }`;

    return dataRow;
  };

const departmentItemDetails =
  ({ forDepartment, location }) =>
  item => {
    const actionText = item.action[0].toUpperCase() + item.action.substr(1);
    const hasDepartment = !!item.department_slug;
    const baseLinkPath = getBaseLinkPath(forDepartment, location);

    const dataRow = initializeDataRow(item, actionText);

    if (!forDepartment && hasDepartment) {
      dataRow.department = (
        <span>
          {item.department_type}:&nbsp;
          <Link
            to={`${baseLinkPath}/${item.department_type}/${item.department_slug}`}
          >
            {item.department_name}
          </Link>
        </span>
      );
    }

    dataRow.details = (
      <span>
        <Link to={`${baseLinkPath}`}>{item.department_name}</Link>
      </span>
    );

    return dataRow;
  };

const credentialLinkItemDetails =
  ({ forDepartment, location }) =>
  item => {
    const actionText = item.action[0].toUpperCase() + item.action.substr(1);
    const hasDepartment = !!item.department_slug;
    const baseLinkPath = getBaseLinkPath(forDepartment, location);

    const dataRow = initializeDataRow(item, actionText);

    if (!forDepartment && hasDepartment) {
      dataRow.department = (
        <span>
          {item.department_type}:&nbsp;
          <Link
            to={`${baseLinkPath}/${item.department_type}/${item.department_slug}`}
          >
            {item.department_name}
          </Link>
        </span>
      );
    }

    dataRow.details = (
      <span>
        <Link
          to={`${trimUriSegments(location.pathname, 9)}/group-credentials/${
            item.info.token
          }`}
        >
          {`Credential Link`}
        </Link>
      </span>
    );

    return dataRow;
  };

const emailItemDetails =
  ({ forDepartment, location }, isCredentialLink = false) =>
  item => {
    const actionText = item.action[0].toUpperCase() + item.action.substr(1);
    const hasDepartment = !!item.department_slug;
    const baseLinkPath = getBaseLinkPath(forDepartment, location);

    const dataRow = initializeDataRow(item, actionText);

    if (!forDepartment && hasDepartment) {
      dataRow.department = (
        <span>
          {item.department_type}:&nbsp;
          <Link
            to={`${baseLinkPath}/${item.department_type}/${item.department_slug}`}
          >
            {item.department_name}
          </Link>
        </span>
      );
    }

    dataRow.details = isCredentialLink ? (
      <span>
        <Link
          to={`${trimUriSegments(location.pathname, 9)}/group-credentials/${
            item.info.token
          }`}
        >
          {`cred link email sent: ${item.info.recipients}`}
        </Link>
      </span>
    ) : (
      <span>
        <Link to={`${trimUriSegments(location.pathname, 9)}`}>
          {`email sent: ${item.info.recipients}`}
        </Link>
      </span>
    );

    return dataRow;
  };

const cateringAvailabilityItemDetails =
  ({ forDepartment, location }) =>
  item => {
    const actionText = item.action[0].toUpperCase() + item.action.substr(1);
    const hasDepartment = !!item.department_slug;
    const baseLinkPath = getBaseLinkPath(forDepartment, location);

    const dataRow = initializeDataRow(item, actionText);

    if (!forDepartment && hasDepartment) {
      dataRow.department = (
        <span>
          {item.department_type}:&nbsp;
          <Link
            to={`${baseLinkPath}/${item.department_type}/${item.department_slug}`}
          >
            {item.department_name}
          </Link>
        </span>
      );
    }

    dataRow.details = (
      <span>
        <Link to={`${baseLinkPath}/catering`}>catering change</Link>
      </span>
    );

    return dataRow;
  };

const departmentGroupItemDetails =
  ({ location }) =>
  item => {
    const departmentGroup = item.after ? item.after : item.before;
    let actionText = 'Updated';
    if (item.before === null) {
      actionText = 'Created';
    }
    if (item.after === null) {
      actionText = 'Deleted';
    }

    const dataRow = initializeDataRow(item, actionText);

    const baseUri = trimUriSegments(location.pathname, 1);
    dataRow.details = (
      <Link to={baseUri + '/edit-department-group/' + departmentGroup.id}>
        {departmentGroup.name}
      </Link>
    );
    dataRow.department = (
      <span>
        {capitalize(departmentGroup.department_type) + ' - department group'}
      </span>
    );

    if (item.after === null)
      dataRow.details = <span>{departmentGroup.name}</span>;

    return dataRow;
  };

const pulseOrderItemDetails =
  ({ forDepartment, location }) =>
  item => {
    const dataRow = initializeDataRow(item, item.action);

    const forContact = !!item.info.pulseOrder.contact_id;

    const name = forContact
      ? getUserName(item.info.pulseOrder.contact)
      : 'Group';

    let baseLinkPath = getBaseLinkPath(forDepartment, location);

    if (!forDepartment)
      baseLinkPath = `${baseLinkPath}/${item.department_type}/${item.department_slug}`;

    dataRow.department = (
      <span>
        {item.department_type}:&nbsp;
        <Link to={baseLinkPath}>{item.department_name}</Link>
      </span>
    );

    dataRow.details = (
      <span>
        Order:&nbsp;
        <Link
          to={`${baseLinkPath}/reconcile${
            forContact ? `/${item.info.pulseOrder.contact_id}` : ''
          }`}
        >
          {item.resource_name}
        </Link>
        &nbsp;for {name}
      </span>
    );

    return dataRow;
  };

export const getResourceDataMap = props => {
  const resourceDataMap = new Map();

  resourceDataMap['department group'] = departmentGroupItemDetails(props);
  resourceDataMap['contact'] = contactItemDetails(props);
  resourceDataMap['credential'] = credentialItemDetails(props);
  resourceDataMap['department'] = departmentItemDetails(props);
  resourceDataMap['credentialLink'] = credentialLinkItemDetails(props);
  resourceDataMap['groupCredentialsLinkEmailSent'] = emailItemDetails(
    props,
    true,
  );
  resourceDataMap['advanceFormLinkEmailSent'] = emailItemDetails(props);
  resourceDataMap['mediaPersonnelApprovedEmailSent'] = emailItemDetails(props);
  resourceDataMap['credentialLinkPersonnelEmailSent'] = emailItemDetails(props);
  resourceDataMap['departmentSummaryEmailSent'] = emailItemDetails(props);
  resourceDataMap['departmentCateringAvailability'] =
    cateringAvailabilityItemDetails(props);
  resourceDataMap['pulseOrder'] = pulseOrderItemDetails(props);

  return resourceDataMap;
};
